document.addEventListener("DOMContentLoaded", () => {
    const neighbourhoodSelect = document.getElementById("neighbourhood");
    const analysisData = document.getElementById("analysis-data");
    const loadingIndicator = document.getElementById("loading");

    const selectDisplay = $('#neighbourhood-select .custom-select-display');
    const selectOptions = $('#neighbourhood-select .custom-select-options');
    const selectedNeighbourhood = $('#selected-neighbourhood');
    const arrowDown = $('.arrow-down');

    selectDisplay.on("click", function () {

        if (selectOptions.css('display') === 'none') {
            selectOptions.css('display', 'block');  
        } else {
            selectOptions.css('display', 'none');  
        }

        arrowDown.css('transform', selectOptions.css('display') === 'block' ? 'rotate(180deg)' : 'rotate(0deg)');
    });

    $('#neighbourhood-select .custom-select-option').click(function() {
        const neighbourhoodName = $(this).text();
        const neighbourhoodId = $(this).data('id');
    
    
        selectedNeighbourhood.text(neighbourhoodName);
        selectOptions.css('display', 'none');  
        arrowDown.css('transform', 'rotate(0deg)');
    
        fetchAnalysisData(neighbourhoodId);
      });

    function fetchAnalysisData(neighbourhoodId) {

        if (!neighbourhoodId) {
            location.reload();
            setTimeout(() => {
                document.getElementById('neighbourhood-select').scrollIntoView({ behavior: 'smooth', block: 'start' });
            }, 1000);
            return;
        }

        $('#analysis-data').addClass('loading');
        $('#loading').removeClass('hidden');

        fetch(`/hr/sto-s-otpadom/analiza?neighbourhood_id=${neighbourhoodId}`, {
            headers: { "X-Requested-With": "XMLHttpRequest" }
        })
            .then(response => response.text())
            .then(html => {
                analysisData.innerHTML = html;
                $('#analysis-data').removeClass('loading');
                $('#loading').addClass('hidden');

                initializeCharts();
            })
            .catch(error => console.error("Error:", error));

    }

    if (neighbourhoodSelect) {
        neighbourhoodSelect.addEventListener("change", function () {
            const neighbourhoodId = this.value;

            $('#analysis-data').addClass('loading');
            $('#loading').removeClass('hidden');

            fetch(`/hr/sto-s-otpadom/analiza?neighbourhood_id=${neighbourhoodId}`, {
                headers: { "X-Requested-With": "XMLHttpRequest" }
            })
                .then(response => response.text())
                .then(html => {
                    analysisData.innerHTML = html;
                    $('#analysis-data').removeClass('loading');
                    $('#loading').addClass('hidden');

                    initializeCharts();
                })
                .catch(error => console.error("Error:", error));
        });
    }

    const renderChart = (chartCanvas, chartType) => {
        const ctx = chartCanvas.getContext('2d');
        let chartConfig;

        if (chartType === 'double-bar') {
            // Special case for grouped bar chart
            const data = JSON.parse(chartCanvas.dataset.items);

            // Convert data to Chart.js format
            const labels = Object.keys(data);
            const cityData = labels.map(label => data[label].city);
            const neighbourhoodData = labels.map(label => data[label].neighbourhood);

            chartConfig = {
                type: 'bar',
                data: {
                    labels: labels,
                    datasets: [
                        {
                            label: 'Grad',
                            data: cityData,
                            backgroundColor: 'rgba(75, 192, 192, 0.2)',
                            borderColor: 'rgb(75, 192, 192)',
                            borderWidth: 1
                        },
                        {
                            label: 'Kvart',
                            data: neighbourhoodData,
                            backgroundColor: 'rgba(192, 75, 75, 0.2)',
                            borderColor: 'rgb(192, 75, 75)',
                            borderWidth: 1
                        }
                    ]
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: true,
                            position: "bottom",
                            labels: {
                                padding: 30,
                                boxWidth: 20,
                                font: {
                                    size: 14,
                                },
                            },
                        },
                    },
                    animation: {
                        duration: 1000,
                        easing: "easeInOutCubic",
                    },
                }
            };
        } else if (chartType === 'radar') {
            const data = JSON.parse(chartCanvas.dataset.items);
            chartConfig = {
                type: 'radar',
                data: data,
                options: {
                    responsive: false,
                    maintainAspectRatio: false,
                    scales: {
                        r: {
                            ticks: {
                                display: false
                            },
                            pointLabels: {
                                font: {
                                    size: 16
                                }
                            }
                        }
                    },
                    plugins: {
                        legend: {
                            position: "bottom",
                            labels: {
                                font: {
                                    size: 20,
                                },
                            },
                        },
                    },
                    animation: {
                        duration: 1000,
                        easing: "easeInOutCubic",
                    },
                }
            };
        } else {
            const data = JSON.parse(chartCanvas.dataset.items);
            const colors = chartCanvas.dataset.colors ? JSON.parse(chartCanvas.dataset.colors) : undefined;

            chartConfig = {
                type: chartType,
                data: {
                    labels: Object.keys(data),
                    datasets: [{
                        label: chartCanvas.dataset.label || 'Iznos',
                        data: Object.values(data),
                        backgroundColor: colors || 'rgba(75, 192, 192, 0.2)',
                        borderColor: colors ? colors.map(color => color.replace('rgba', 'rgb')) : 'rgb(89, 192, 75)',
                        borderWidth: 1
                    }]
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: false,
                            position: "bottom",
                            labels: {
                                padding: 30,
                                boxWidth: 20,
                                font: {
                                    size: 20,
                                },
                            },
                        },
                    },
                    animation: {
                        duration: 1000,
                        easing: "easeInOutCubic",
                    },
                }
            };
        }

        new Chart(ctx, chartConfig);
    };

    const setupObserversForCharts = (selector, chartType) => {
        document.querySelectorAll(selector).forEach(chartCanvas => {
            const observer = new IntersectionObserver((entries, observer) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        renderChart(chartCanvas, chartType);
                        observer.disconnect();
                    }
                });
            });

            observer.observe(chartCanvas);
        });
    };

    const initializeCharts = () => {
        setupObserversForCharts("canvas.bar-chart", 'bar');
        setupObserversForCharts("canvas.line-chart", 'line');
        setupObserversForCharts("canvas.pie-chart", 'pie');
        setupObserversForCharts("canvas.radar-chart", 'radar');
        setupObserversForCharts("canvas.double-bar-chart", 'double-bar');
    };
    // Run on initial page load
    initializeCharts();
});